<template>
  <div class='study'>
    <layout-header />
    <main class='study__main base-content-pc'>
      <section class='study__main__left' v-if='isActive.loadMarkdown && isActive.loadConfig'>
        <div class='study__main__left__chapter'>
          <div  class='study__main__left__chapter__config'>
            <p>{{ setLessonTitle }}</p>
            <h3>{{ setChapterTitle }}</h3>
          </div>
          <div class='study__main__left__chapter__markdown'>
            <p class='mdbase' v-html='parseMarkdown' />
          </div>
          <div class='study__main__left__chapter__func'>
            <button-check-box
              :isActive='isActive.progressCheckBox'
              text='チャプターの内容を理解しました'
              @emit='changeProgress()'
            />
            <div class='study__main__left__chapter__func__move'>
              <button-normal
                v-if='showPrevChapterButton'
                white
              >
                <router-link :to='{
                  name: "Study",
                  params: setPrevChapterParam
                }'>
                  前のチャプターに戻る
                </router-link>
              </button-normal>
              <button-normal v-if='showNextChapterButton'>
                <router-link :to='{
                  name: "Study",
                  params: setNextChapterParam
                }'>
                  次のチャプターに進む
                </router-link>
              </button-normal>
            </div>
            <button-normal>
              <router-link :to='{
                name: "Dashboard" }'>
                トップページに戻る
              </router-link>
            </button-normal>
          </div>
          <div class="study__main__left__chapter__tweet">
            <button-twitter-share
              buttonTitle="Twitterで学習進捗をシェア"
              :tweetText="createTweetText"
            />
          </div>
        </div>
      </section>
    </main>
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';
import ButtonCheckBox from '@/components/atoms/button/ButtonCheckBox.vue';
import ButtonTwitterShare from '@/components/atoms/button/ButtonTwitterShare.vue';
import axios from 'axios';
import { marked } from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
import dompurify from 'dompurify';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonNormal,
    ButtonCheckBox,
    ButtonTwitterShare,
  },
  data() {
    return {
      isActive: {
        loadMarkdown: false,
        loadConfig: false,
        progressCheckBox: false,
      },
      markdown: null,
      categoryId: Number(this.$route.params.categoryId),
      lessonId: Number(this.$route.params.lessonId),
      chapterId: Number(this.$route.params.chapterId),
      lessonInfo: [],
    };
  },
  async created() {
    // 無料枠制御Lesson
    if (this.$store.state.study.courseInfo.id === 1
      && this.lessonId > this.lessonLimit) {
      this.$router.push({ path: '/notfound' });
    }
    // 無料枠制御Chapter
    if (this.$store.state.study.courseInfo.id === 1
      && this.lessonId + 1 > this.lessonLimit
      && this.chapterId > this.chapterLimit) {
      this.$router.push({ path: '/notfound' });
    }
    const result = await this.$store.dispatch('loadLessonInfo');
    if (!result) await this.$router.push({ name: 'Error' });
  },
  mounted() {
    // Vuexからレッスン情報の取得
    const targetLessonInfo = this.$store.state.study.lessonInfo
      .filter((v) => v.categoryId === this.categoryId && v.lessonId === this.lessonId)[0];
    this.lessonInfo = targetLessonInfo;
    this.isActive.loadConfig = true;

    if (targetLessonInfo === undefined
      || targetLessonInfo.chapterName.length < this.chapterId) {
      this.$router.push({ path: '/notfound' });
    } else {
      // 進捗情報の取得
      this.loadProgress();
      // マークダウンテキストの取得
      this.loadMarkdown();
      // ページタイトル設定
      this.setPageTitle();
    }
  },
  computed: {
    lessonLimit() {
      return Number(process.env.VUE_APP_FREE_COURSE_LESSON_LIMIT);
    },
    chapterLimit() {
      return Number(process.env.VUE_APP_FREE_COURSE_CHAPTER_LIMIT);
    },
    parseMarkdown() {
      const renderer = new marked.Renderer();
      // 画像のサイズ調整
      renderer.image = (src, title, alt) => {
        const [width, height] = title?.startsWith('=') ? title.slice(1).split('x').map((v) => v.trim()).filter(Boolean) : [];
        return `<img src="${src}" alt="${alt}"${width ? ` width="${width}"` : ''}${height ? ` height="${height}"` : ''}>`;
      };

      marked.setOptions({
        langPrefix: '',
        highlight: (code, lang) => hljs.highlightAuto(code, [lang]).value,
        renderer,
      });

      // markdown内の他サイトへのリンクにtarget="_blank"を付与する
      const linkRenderer = renderer.link;
      renderer.link = (href, title, text) => {
        const localLink = href.startsWith(`${window.location.protocol}//${window.location.hostname}`);
        const html = linkRenderer.call(renderer, href, title, text);
        return localLink ? html : html.replace(/^<a /, '<a target="_blank" rel="noreferrer noopener nofollow" ');
      };
      return dompurify.sanitize(marked(this.markdown), { ADD_ATTR: ['target'] });
    },
    setChapterTitle() {
      return `${this.chapterId}. ${this.lessonInfo.chapterName[this.chapterId - 1]}`;
    },
    setLessonTitle() {
      return `${this.viewLessonId()}. ${this.lessonInfo.lessonName}`;
    },
    setPrevChapterParam() {
      return {
        categoryId: this.categoryId,
        lessonId: this.lessonId,
        chapterId: this.chapterId - 1,
      };
    },
    setNextChapterParam() {
      return {
        categoryId: this.categoryId,
        lessonId: this.lessonId,
        chapterId: this.chapterId + 1,
      };
    },
    showPrevChapterButton() {
      return this.chapterId !== 1;
    },
    showNextChapterButton() {
      // 無料枠制御 chapter
      if (this.$store.state.study.courseInfo.id === 1
        && this.lessonId + 1 > this.lessonLimit
        && this.chapterId + 1 > this.chapterLimit) {
        return false;
      }
      return this.lessonInfo.chapterName.length !== this.chapterId;
    },
    createTweetText() {
      const { lessonName } = this.lessonInfo;
      const lessonId = this.viewLessonId();
      const { name } = this.$store.state.study.courseInfo;

      const line1 = 'サブスクで学べるプログラミング「%20%23Donbler%20」';
      const line2 = `${name}の「レッスン${lessonId}: ${lessonName}」を学習中！`;
      const line3 = document.URL;

      return `${line1}%0a${line2}%0a%0a${line3}`;
    },
  },
  methods: {
    loadMarkdown() {
      axios.get(`${process.env.VUE_APP_S3_MATERIAL_URL}/educationContents/${this.categoryId}/contents/${this.lessonId}/${this.chapterId}/chapter.md`)
        .then((res) => {
          this.markdown = res.data;
          this.isActive.loadMarkdown = true;
        })
        .catch(() => this.$router.push({ name: 'Error' }));
    },
    loadProgress() {
      const targetProgress = this.$store.state.study.progressInfo
        .filter((v) => v.categoryId === this.categoryId
      && v.lessonId === this.lessonId && v.chapterId === this.chapterId);
      this.isActive.progressCheckBox = targetProgress.length !== 0;
    },
    changeIsActive(target) {
      this.isActive[target] = !this.isActive[target];
    },
    viewLessonId() {
      return this.$store.state.study.lessonInfo
        .findIndex((v) => v.categoryId === this.categoryId && v.lessonId === this.lessonId) + 1;
    },
    async changeProgress() {
      await this.changeIsActive('progressCheckBox');
      const actionName = await this.isActive.progressCheckBox
        ? 'createProgress' : 'deleteProgress';

      const result = await this.$store.dispatch(actionName, {
        category_id: this.categoryId,
        lesson_id: this.lessonId,
        chapter_id: this.chapterId,
      });

      if (result === 401) this.$router.push({ name: 'Top' });
      if (!result) {
        this.changeIsActive('progressCheckBox');
        this.$notify({
          title: 'チャプターの進捗の送信に失敗しました',
          text: 'しばらく時間を空けてから再度お試しください',
          speed: 400,
          type: 'error',
          group: 'top-right',
        });
      }
    },
    setPageTitle() {
      document.title = `${this.lessonInfo.chapterName[this.chapterId - 1]} | プログラミング学習レッスンプラットフォームDonbler(ドンブラー)`;
    },
  },
  watch: {
    // studyページからstudyページへ移動した際の処理
    $route() {
      this.categoryId = Number(this.$route.params.categoryId);
      this.lessonId = Number(this.$route.params.lessonId);
      this.chapterId = Number(this.$route.params.chapterId);
      this.loadProgress();
      this.loadMarkdown();
      this.setPageTitle();
      window.scrollTo({ top: 0 });
    },
  },
};
</script>

<style lang="scss" scoped>
.study {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.study__main {
  margin: 0 auto 80px;
  max-width: 100%;
}

.study__main__left {
  width: 100%;
}

.study__main__left__chapter {
  background-color: $color-white;
  padding: 30px;
}

.study__main__left__chapter__config {
  border-bottom: 2px solid #F0F4F4;
  padding-bottom: 15px;
  margin-bottom: 30px;
  h3 {
    font-size: 32px;
  }
  p {
    font-weight: 500;
    font-size: 16px;
  }
}

.study__main__left__chapter__tweet {
  text-align: right;
  margin-top: 40px;
}

.study__main__left__chapter__func {
  text-align: center;
  margin-top: 80px;
}

.study__main__left__chapter__func__move {
  margin: 20px 0;
  button {
    margin: 0 5px;
  }
}
</style>

<style lang="scss">
.mdbase {
  li {
    list-style: initial;
  }
}
</style>
